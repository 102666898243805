const glider = new Glider(document.querySelector(".glider"), {
  slidesToShow: "auto",
  itemWidth: 270,
  itemHeight: 354,
  gap: "1rem",
});

const next = document.querySelector("#next");
const prev = document.querySelector("#prev");

next.addEventListener("click", () => {
  glider.scrollItem(glider.slide + 1);
});

prev.addEventListener("click", () => {
  glider.scrollItem(glider.slide - 1);
});

const toggleButtons = document.querySelectorAll(
  ".menu-button, .menu-button.close"
);
const navbarContainer = document.querySelector("nav");
const navbarLinks = document.querySelectorAll("nav ul li a");

navbarLinks.forEach((link) => {
  link.addEventListener("click", () => {
    navbarContainer.classList.remove("active");
  });
});

toggleButtons.forEach((button) => {
  button.addEventListener("click", () => {
    navbarContainer.classList.toggle("active");
  });
});

const images = document.querySelectorAll(".slide-image img");
const lightbox = document.querySelector("#lightbox");
const lightboxImage = document.querySelector("#lightbox-image");
const closeButton = document.querySelector(".close-lightbox");
const lightboxText = document.querySelector("#lightbox-text");
const body = document.querySelector("body");

let index = 0;
const changeImage = (newIndex) => {
  lightboxImage.src = images[newIndex].src;
  index = newIndex;
  lightboxText.innerHTML = `${newIndex + 1} de ${images.length}`;

  const isPortrait = lightboxImage.naturalHeight > lightboxImage.naturalWidth;
  lightbox.classList.remove("hidden");
  body.classList.add("lightbox-open");
  if (isPortrait) {
    lightboxImage.style.width = "100%";
    lightboxImage.style.height = "100%";
  } else {
    lightboxImage.style.width = "auto";
    lightboxImage.style.height = "100%";
  }
  requestAnimationFrame(() => {
    lightbox.classList.add("visible");
  });
};

images.forEach((image, index) => {
  image.addEventListener("click", (event) => {
    changeImage(index);
  });
});

closeButton.addEventListener("click", () => {
  body.classList.remove("lightbox-open");
  lightbox.classList.remove("visible");
  lightbox.addEventListener(
    "transitionend",
    () => {
      lightbox.classList.add("hidden");
    },
    { once: true }
  );
});

const lightboxNavigation = document.querySelectorAll(".navigation-button");
lightboxNavigation.forEach((button) => {
  button.addEventListener("click", (event) => {
    if (button.id === "prev") {
      if (index === 0) {
        return;
      }
      changeImage(index - 1);
    } else if (button.id === "next") {
      if (index === images.length - 1) {
        return;
      }
      changeImage(index + 1);
    }
  });
});
